import * as React from "react";
import { withPreviewResolver } from "gatsby-source-prismic";

const PreviewPage = ({ isPreview, isLoading }) => {
  if (isPreview === false) return "Not a preview!";

  return (
    <div>
      <p>Loading</p>
    </div>
  );
};

export default withPreviewResolver(PreviewPage, {
  repositoryName: `parkvillage`,
  // linkResolver: ({ node, key, value }) => doc => {
  //   // Your link resolver
  //   if (doc.type === "homepage") {
  //     return `/`;
  //   } else if (doc.type === "custom_reel") {
  //     return `/${doc.uid}`;
  //   } else if (doc.type === "director") {
  //     return `/directors/${doc.uid}`;
  //   } else if (doc.type === "directors") {
  //     return `/directors`;
  //   } else if (doc.type === "event") {
  //     return `/events`;
  //   } else if (doc.type === "events") {
  //     return `/events`;
  //   } else if (doc.type === "experiential") {
  //     return `/experiential`;
  //   } else if (doc.type === "food_directors") {
  //     return `/food-and-drink-directors`;
  //   } else if (doc.type === "page") {
  //     return `/${doc.uid}`;
  //   } else if (doc.type === "studio") {
  //     return `/studios`;
  //   } else if (doc.type === "studios") {
  //     return `/studios`;
  //     // } else if (doc.type === "video") {
  //     // return `/${doc.uid}`;
  //     // return `/${doc.data.director.uid}/${doc.uid}`;
  //   } else {
  //     return `/${doc.uid}`;
  //   }
  // },
  linkResolver: ({ node, key, value }) => doc => {
    // Your link resolver
    if (doc.type === "homepage") {
      return `/`;
    } else if (doc.type === "custom_reel") {
      return `/${doc.uid}`;
    } else if (doc.type === "director") {
      return `/film/${doc.uid}`;
    } else if (doc.type === "directors") {
      return `/film`;
    } else if (doc.type === "event") {
      return `/events`;
    } else if (doc.type === "events") {
      return `/events`;
    } else if (doc.type === "experiential") {
      return `/experiential`;
    } else if (doc.type === "food_directors") {
      return `/food-and-drink`;
    } else if (doc.type === "page") {
      return `/${doc.uid}`;
    } else if (doc.type === "studio") {
      return `/studios`;
    } else if (doc.type === "studios") {
      return `/studios`;
    } else if (doc.type === "text_page") {
      return `/${doc.uid}`;
    } else if (doc.type === "photographer") {
      return `/photography/${doc.uid}`;
    } else if (doc.type === "photography_project") {
      return `/photography/${doc.data.photographer.uid}/${doc.uid}`;
    } else if (doc.type === "article") {
      return `/stories/${doc.uid}`;
    } else {
      return `/${doc.uid}`;
    }
  },
});
